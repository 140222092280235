import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import {setScene, updateAppSync, createAppSync, pushGtm,updateTimer } from '../actions/index'
import * as action from '../actions/index'
import BackButton from './buttons/ButtonsA'   
import SceneButton from './buttons/ButtonsB'
import InfoButton from './buttons/ButtonsC' 
import styled from 'styled-components'

export default function Item() {
  const [isDisabled, setDisabled] = useState(false) 
  const [tab, setTab] = useState("ITEM") 
  const digitizer = useSelector(state => state.digitizer);
  const gtm = useSelector(state => state.gtm);
  const selectedModel = useSelector(state => state.selected); 
  const timer = useSelector(state => state.timer); 

  const dispatch = useDispatch();

  const hundleChangeScene = (scene) => () => {
    let gtm_operation = "BUTTON"
    setDisabled(true)
    try{
      const input = { 
        state: "TRYON", 
        store_code:  digitizer,
        }

      switch (scene) {
        case "Top":
          input.state = "STANDBY"
          gtm_operation = "RETRY"
          break;
        case "Guide1":
          input.state = "GUIDE_01"
          input.shoe_model =  selectedModel
          break;
        case "Tryon":
        defualt:
          input.shoe_model = selectedModel 
          break;
      }

      action.createAppSync(input)
      action.setScene(scene, dispatch)
      action.pushGtm(gtm, { state: input.state, operation: gtm_operation, shoe_id : input.shoe_model ? input.shoe_model : null} )
      action.updateTimer(timer, gtm, dispatch)
      setDisabled(false)
    } catch(e) {
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }

  const hundleChangeTab = (tab) => () => {
    setDisabled(true)
    try {
      const input = { 
        state: tab, 
        store_code: digitizer,
        shoe_model:selectedModel
      }
      action.createAppSync(input)
      setTab(tab)
      action.pushGtm(gtm, { state: input.state, operation: "SELECT", shoe_id : selectedModel} )
      action.updateTimer(timer, gtm, dispatch)
      setDisabled(false)
    } catch (e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }

  return (
    <Root>
      <Header>商品詳細</Header>
      <ButtonList>
        <InfoButton  
          isDisabled={isDisabled} 
          onTouchStart={hundleChangeTab("ITEM")} 
          selected={(tab === "ITEM")} >商品詳細</InfoButton>
        <InfoButton 
          isDisabled={isDisabled} 
          onTouchStart={hundleChangeTab("ABOUT")} 
          selected={(tab === "ABOUT")}>素材について</InfoButton>
        <InfoButton 
          isDisabled={isDisabled} 
          onTouchStart={hundleChangeTab("SNAP")}  
          selected={(tab === "SNAP")}>スナップ</InfoButton>
        <InfoButton 
          isDisabled={isDisabled} 
          onTouchStart={hundleChangeTab("REVIEW")} 
          selected={(tab === "REVIEW")}>レビュー</InfoButton>
      </ButtonList>

      <Buttons>
        <ItemButton 
          isDisabled={isDisabled} 
          onTouchStart={hundleChangeScene} scene={"Tryon"}>商品詳細を閉じる</ItemButton>
        <MeasurementButton 
          isDisabled={isDisabled} 
          onTouchStart={hundleChangeScene} scene={"Guide1"}>購入手続きにすすむ</MeasurementButton>
      </Buttons>

      <TopButton 
        isDisabled={isDisabled} 
        onTouchStart={hundleChangeScene}  scene={"Top"} >TOPに戻る</TopButton>

    </Root>
  );
}

const Root = styled.div`
  position: relative;
  height: 100vh; /* 全画面表示 */
`

const Header = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 2em;
  font-weight: bold;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.key};
  width: 100%;
  height: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
  align-items: center; /* 縦方向中央揃え */
  -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
  justify-content: center; /* 横方向中央揃え */
`

const ButtonList = styled.ul`
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  -webkit- transform: translateX(-50%);  
  display: grid;
  grid-template-columns: 1fr 1fr;
  /*grid-auto-rows: 250px;*/
  > li {
    margin-right: 10px;
    margin-left: 10px;
  }
`

const Buttons = styled.div`
  width: 100%;
  position: absolute;
  bottom: 100px;
  text-align: center;
`
const ItemButton = styled(SceneButton)`
`
const MeasurementButton = styled(SceneButton)`
`


const TopButton = styled(BackButton)`
  position: absolute;
  bottom: 10px;
  left: 30px;
`