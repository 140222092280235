import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import {setScene, updateAppSync, createAppSync, pushGtm } from '../actions/index'
import * as action  from '../actions/index'
import QRCpde from 'qrcode.react'
import BackButton from './buttons/ButtonsA'   
import styled from 'styled-components'
import restartBtn from '../assets/images/restart.svg'
import { TweenMax } from "gsap"

export default function Ecqrcode() {

  const [isDisabled, setDisabled] = useState(false)
  const s = useSelector(state => state);
  const ec = useSelector(state => state.ec);
  const digitizer = useSelector(state => state.digitizer);
  const gtm = useSelector(state => state.gtm)
  const selectedModel = useSelector(state => state.selected)
  const dispatch = useDispatch();
  const NextBtnEl = useRef(null)

  useEffect(() => {
    TweenMax.to(NextBtnEl.current, 0.5,{ scale: 1, delay: 0, ease: "power2.inOut"})
  }, [])

  function updateGraphql (state) {
    let input = { state: state, store_code: digitizer}
    try {
      action.createAppSync(input)
    } catch(e) {
      dispatch({type: "SET_ERROR", error:e.message})
    }
    action.pushGtm(gtm, { state: input.state, operation: "RESET" })
  }
  
  const hundleChangeScene = (scene) => () => {
    setDisabled(true)
    let state =  "TOP"
    updateGraphql(state)
    action.setScene(scene, dispatch)
  }

  return (
    <Root>
      {/**
       * 
            <Header>
        <li>
          <Stepoff>
            <dt>1</dt>
            <dd>デザインを選ぶ</dd>
          </Stepoff>
        </li>
        <li>
          <Stepoff>
            <dt>2</dt>
            <dd>サイズを調べる</dd>
          </Stepoff>
        </li>
        <li>
          <Stepon>
            <dt>3</dt>
            <dd>購入する</dd>
          </Stepon>
        </li>
      </Header>
       */}
      <Measuring>
        {/**
         * 
            <Text>ディスプレイに表示された<br />QRコードを読み込んで<br />購入してください！</Text>
         */}
            <TopButton ref={NextBtnEl} disabled={isDisabled}  type="button" disabled={isDisabled} onTouchStart={hundleChangeScene('Top')}>
            <img src={restartBtn} width="400px" />
            </TopButton>
        {/**
            <Text2>サイズは、購入ページで変更が可能です。</Text2>
         */}

      </Measuring>

    </Root>
  );
}


const Root = styled.div`
  position: relative;
  height: 100vh; /* 全画面表示 */
`

const Header = styled.ul`
  position: absolute;
  top: 0px;
  left: 0px;
  background: ${({ theme }) => theme.color.grey};
  width: 100%;
  height: 190px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
  align-items: center; /* 縦方向中央揃え */
  -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
  justify-content: center; /* 横方向中央揃え */
  > li  {
    width: 200px;

  }
`
const Stepon = styled.dl`
  text-align: center;
    > dt {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.key};
      text-align: center;
      color: #fff;
      margin: auto;
      font-size: 1.8em;
      font-weight: bold;
    }
    > dd {
      margin-top: 5px;
      font-size: 1.5em;
      font-weight: bold;


    }
`
const Stepoff = styled.dl`
  text-align: center;
    > dt {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.grey7};
      text-align: center;
      color: #fff;
      margin: auto;
      font-size: 1.8em;
      font-weight: bold;
    }
    > dd {
      color: ${({ theme }) => theme.color.grey4};
      margin-top: 5px;
      font-size: 1.5em;
      font-weight: bold;

    }
`

const Measuring = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit- transform: translateY(-50%) translateX(-50%);
  width: 100%;
  text-align: center;
`
const Inner = styled.div`
  position: relative;
`
const InfoText = styled.p`
  width: 100%;

`
const Qr = styled(QRCpde)`
  padding: 0;
  margin: 100px 0;
`

const Text = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.color.grey2};
  font-size: 2.0em;
  font-weight: bold;
  text-align: center;
`

const TopButton = styled.button`
`

const Text2 = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit- transform: translateX(-50%);
  width: 700px;
  color: ${({ theme }) => theme.color.grey2};
  font-size: 1.5em;
  text-align: center;
`