import humps from 'humps'
import config from '../config'; 
import {fdApiFetchWithErrorHandling, fetchWithErrorHandling, recommendApiFetchWithErrorHandling, slackFetchWithErrorHandling } from '../api'; 
import * as sheet from '../helpers/spredSheet';
import API, { graphqlOperation } from '@aws-amplify/api';
//import PubSub from '@aws-amplify/pubsub'
import awsconfig from '../aws-exports'
import { createCommand } from '../graphql/mutations';
import { listCommands } from '../graphql/queries';
import { getErrorMsg } from '../helpers/error';

const FFAPI=`${config.API[config.STAGE]}`
const MASTERJSON=`${config.MASTERJSON[config.STAGE]}`
const REFRESHTIMER =`${config.REFRESHTIMER}`

API.configure(awsconfig);
//PubSub.configure(awsconfig);

function UserException(code, org) {
    const errmsg = getErrorMsg(code)
    this.code = code
    this.message = errmsg.msg
    this.org = org
    this.sendstate = errmsg.state
}

export function addCourseAction(title) {
  console.log('nodeenv' , process.env.NODE_ENV)
  console.log('API' , FFAPI)
  return { type: 'ADD_COURSE', title }
}

export const dateFormat = {
    _fmt : {
      "yyyy": function(date) { return date.getFullYear() + ''; },
      "MM": function(date) { return ('0' + (date.getMonth() + 1)).slice(-2); },
      "dd": function(date) { return ('0' + date.getDate()).slice(-2); },
      "hh": function(date) { return ('0' + date.getHours()).slice(-2); },
      "mm": function(date) { return ('0' + date.getMinutes()).slice(-2); },
      "ss": function(date) { return ('0' + date.getSeconds()).slice(-2); }
    },
    _priority : ["yyyy", "MM", "dd", "hh", "mm", "ss"],
    format: function(date, format){
      return this._priority.reduce((res, fmt) => res.replace(fmt, this._fmt[fmt](date)), format)
    }
};


export function pushSheet() {
    const key = "logs"
    if (!localStorage.getItem(key)) {
        return
    }
    const pastlogs = localStorage.getItem(key)
    const logs = JSON.parse(pastlogs)
    localStorage.removeItem(key)

    sheet.SpreadSheetService()
    .then(data => {
        console.log(data, logs)
        return sheet.insertRows( data ,logs )
    })
}

export function pushLogLocalStorage(gtm) {
    let logs = []
    const key = "logs"
    if (localStorage.getItem(key)) {
        const pastlogs = localStorage.getItem(key)
        logs = JSON.parse(pastlogs);
    } 
    gtm.host = window.location.host
    logs.push (gtm)
    localStorage.setItem(key, JSON.stringify(logs))
}

export function pushGtm(gtm, data) {

    gtm.created_at = dateFormat.format(new Date(), 'yyyy/MM/dd hh:mm:ss');
    gtm.state = data.state
    gtm.operation =  data.operation
    if(data.shoe_id) gtm.shoe_id =  data.shoe_id
    if(data.foot_uuid) gtm.foot_uuid =  data.foot_uuid
    if(data.fit) gtm.fit =  data.fit
    if(data.recommended_size) gtm.recommended_size =  data.recommended_size
    window.dataLayer.push(gtm)
    //pushSheet(gtm)
     pushLogLocalStorage(gtm)
}

export function updateTimer(timer=null, gtm=null, dispatch)  {
    //if(timer === null) return
    ClearTimer(timer, dispatch )
    const timeoutid = setTimeout( () => timeout(gtm, dispatch), REFRESHTIMER )
    dispatch( { type: 'SET_TIMER', timer: timeoutid})
}

export function ClearTimer(timer=null, dispatch)  {
    if(timer === null) return
    clearTimeout(timer);
    dispatch( { type: 'SET_TIMER', timer: null })
}


export function resultTimer(timer=null, gtm=null, dispatch)  {
    //if(timer === null) return
    ClearTimer(timer, dispatch )
    const timeoutid = setTimeout( () => timeout(gtm, dispatch),  (60000 * 10) )
    dispatch( { type: 'SET_TIMER', timer: timeoutid})
}


export function timeout(gtm, dispatch)  {
    //dispatch( setScene("Top"))
    setScene("Top", dispatch)

    let input = { state: "TOP", store_code: gtm.store_code}
    // AppSync
    createAppSync(input)

    // GTM
    pushGtm(gtm, { state: "TOP", operation: "TIMEOUT"} )
}

export function setScene(scene, dispatch)  {
    console.log("action SetSCENE", scene)
    dispatch({
      type: 'SET_SCENE', scene: scene 
    }) 
}

export async function createAppSync(input)  {
    console.log("APP SYNC STATE CREATE" , input.state)
    try {
        return  API.graphql(graphqlOperation( createCommand, { input: input }))
    } catch (err) {
        console.log(err)
        //dispatch({ type: 'SET_ERROR', error :  `DB Error： ${err.message}` })
        throw new UserException( 505, err.message);
    }
}

export async function  getAppSync(digitizer)  {
    try {
        const commanData = await API.graphql(graphqlOperation(listCommands, {
        filter: {
          store_code: {
              eq: digitizer
          }
        }
        }));
        if (commanData.data.listCommands.items.length == 0 ){
            return null
        } else {
            return commanData.data.listCommands.items[0].id
        }
    } catch (err) {
        throw new UserException( 505, err.message);
    }
}

export async function sendSlackMessage(digitizer, uuid){
    console.log("sendSlackMessage")
    const queryParam = window.location.search.substring(1).split('&').map((p) => p.split('=')).reduce((obj, e) => ({...obj, [e[0]]: e[1]}), {});
    if ( process.env.REACT_APP_BUILD_ENV === "development" || queryParam.store ) {
        return;
    }

    const datetime = dateFormat.format(new Date(), 'yyyy/MM/dd hh:mm:ss');
    try {
        let res = await slackFetchWithErrorHandling (
        {
            method: 'post',
            body: {
                    "text": `【利用開始】\n${datetime}\nFD：${digitizer}\nSESSION：${uuid}\nHOST : ${window.location.host}`, 
                }
        })
    } catch (err) {
        console.log("error =====", err)
        throw new UserException( 504, err.message);

    }
};

export async function getFdcode(dispatch){
    const queryParam = window.location.search.substring(1).split('&').map((p) => p.split('=')).reduce((obj, e) => ({...obj, [e[0]]: e[1]}), {});
    try {
        let res
        if ( process.env.REACT_APP_BUILD_ENV !== "development" || !queryParam.store ) {
            res = await fdApiFetchWithErrorHandling('/api/mng/digitizer')
        } else {
            res =  humps.camelizeKeys({ data: {digitizer_code: "999999" }})
            res.data.digitizerCode = queryParam.store
        }


        console.log(res.data.digitizerCode)
        dispatch({ type: 'SET_DIGITIZER', digitizer :  res.data.digitizerCode })
        return res.data.digitizerCode
    } catch (err) {
        //alert(err.message)
        throw new UserException( 503, err.message);
    }
      /*
   return await fdApiFetchWithErrorHandling('/api/mng/digitize').catch(
        e => {
            return e.message
        }
    );
    */
};

export function getItems(digitizer, dispatch){
    fetch(MASTERJSON)
    .then(res => res.json())
    .then( jsonlist=> {
        console.log("JSONLIST " , jsonlist)
        let jsonurl = jsonlist["DEFAULT"]["items_json_endpoint"]
        if (  jsonlist[digitizer] )  {
            jsonurl = jsonlist[digitizer]["items_json_endpoint"]
        } 
        return fetch(jsonurl)
    })
    .then(res => res.json())
    .then( items => {
        console.log("ITEMS ", items)
        dispatch({ type: 'SET_ITEM', items: humps.camelizeKeys(items) })
    })
    .catch((e) => {  
        throw new UserException( 506, e.message)
    });
    //.catch((e) => { throw Error(e); })
    /*
    fetch("https://s3-ap-northeast-1.amazonaws.com/dev.minimum-store/json/items.json")
    .catch((e) => {  new UserException( 506, e.message) })
    //.catch((e) => { throw Error(e); })
    .then(res => res.json())
    .then(items => {
        dispatch({ type: 'SET_ITEM', items: humps.camelizeKeys(items) })
    });
    */

};

export async function getFootUuid(digitizerCode, dispatch){
    const queryParam = window.location.search.substring(1).split('&').map((p) => p.split('=')).reduce((obj, e) => ({...obj, [e[0]]: e[1]}), {});
    try {
        let res  =  humps.camelizeKeys({"status_code":200,"data":{"foot_uuid":"81200270010001-000050-20200828-0006"}})
        if ( process.env.REACT_APP_BUILD_ENV !== "development" || !queryParam.store) {
            res = await fetchWithErrorHandling(`/foot/measurement/init`, {
                method: 'post',
                body: {digitizerCode: digitizerCode}
            })
        }
        console.log(res)
        
        if (res.statusCode === 200) {
            dispatch({ type: "SET_FOOTUUID", footuuid: res.data.footUuid})
            return res.data.footUuid
        } 
        throw new UserException( 506, res.message);
    } catch (err) {
        throw new UserException( 504, err.message);
    }
}

export async function scan(footUuid){
    const queryParam = window.location.search.substring(1).split('&').map((p) => p.split('=')).reduce((obj, e) => ({...obj, [e[0]]: e[1]}), {});
    try {
        if ( process.env.REACT_APP_BUILD_ENV !== "development" || !queryParam.store ) {
            let res = await fdApiFetchWithErrorHandling(`/api/scan/${footUuid}?mode=both`)
            // TODO エラー内容チェック
        }
    } catch (err) {
        throw new UserException( 503, err.message);
    }
}


export async function getJisSize(footUuid, dispatch ){
    try {
        let res = await fetchWithErrorHandling(`/foot/${footUuid}` )

        if (res.statusCode === 200) {
            console.log("GET JISSIZE", res)
            const { foot : {left, right} } = res.data
            dispatch({type: "SET_JISSIZE", jis: {left: left, right: right}})
            return {left: left, right: right}
        } 
        throw new UserException( 506, res.message);
    } catch (err) {
        throw new UserException( 504, err.message);
    }
}

export async function getRecommend(size, width, productNumber, dispatch ){
    try {

        let res = await recommendApiFetchWithErrorHandling("",
        {
            method: 'post',
            body: {
                    "footLength": size, 
                    "footWidth": width, 
                    "productNumber": productNumber
                }
        })

        if (res.statusCode == 200) {
            //const data = JSON.parse(res.body)
            const data = res.body
            dispatch({ type: "SET_RECOMMEND", fittingRate: data.fittingRate, size: data.size  })
            return  data.size
        } else if (res.statusCode == 500 && res.message == 'out of size range. no recommend.' ) { 
            dispatch({ type: "SET_RECOMMEND", fittingRate: 0, size: 'no_matches'  })
            return  'no_matches'
        }
        throw new UserException( 506, res.message);
    } catch (err) {
        throw new UserException( 504, err.message);
    }
}




export async function watchMeasurement(footUuid, pollingState,dispatch){




    try {
        const res = await pollingMeasurement(
            () => {
                return  fetchWithErrorHandling(`/foot/measurement/status/${footUuid}`).catch((e) => {
                    throw new Error()
                })
            },
            (status) => {
                return dispatch({
                type: "SET_MEASUREMENT_STATUS",
                scanStatus: status
                })
            },
            pollingState
            )
        const { left, right } = res.data.measValue
        const file = res.data["3dUrl"] 
        
        dispatch({
            type: "REGISTER_MEASUREMENT",
            payload: {
              measurement: { left, right },
              file: file,
              ec: ""
            }
        })
        return { left, right, file: file }
    } catch (error) {
        const rsError = error.message.match(/Realsense/);
        if(rsError !== null) {
            //dispatch(actions.networkError(`計測情報の取得に失敗しました。<br>再撮影してください。<br/><br/>${error}`))
            console.log(`計測情報の取得に失敗しました。`)
            throw new UserException( 501, error.message);
        }

        const measurementError = error.message.match(/calculate_measurement/);
        if(measurementError !== null) {
            throw new UserException( 403, error.message);
        }

        const reconstructionError = error.message.match(/calculate_reconstruction/);
        if(reconstructionError !== null) {
            throw new UserException( 401, error.message);
        }

        const transformationError = error.message.match(/calculate_transformation/);
        if(transformationError !== null) {
            throw new UserException( 402, error.message);
        }
        throw new UserException( 502, error.message);
    }
}


function pollingMeasurement(asyncFunc, dispatchFunc, pollingState ) {
    const { timeout, interval } =  config.POLLINGSETTINGS

    const endTime = Date.now() + timeout
    /* DEBUG */
    const startTime = Date.now()

    const queryParam = window.location.search.substring(1).split('&').map((p) => p.split('=')).reduce((obj, e) => ({...obj, [e[0]]: e[1]}), {});

    let prevStatus = null
    let statusChange = false
    const checkCondition = async (resolve, reject) => {
        let response = await asyncFunc()
        if ( process.env.REACT_APP_BUILD_ENV === "development" && queryParam.store ) {
            /* DEBUG */
            const diff =  Date.now() - startTime
            // scanStatus: ‘received’, ‘scanned’, ‘3d_uploaded’, ‘processed’, ‘result_uploaded
            if (diff > 0 && diff < 2000) {
              response.data.status = 'received'
            } else if ( diff >= 2000 && diff < 4000 ) {
              response.data.status = 'scanned'
            } else if ( diff >= 4000 && diff < 5000 ) {
              response.data.status = '3d_uploaded'
              /*
              response.data.status = 'error'
              response.data.error_detail = "[algorithm] _foot_reconstruction_measurement error. Critical error in calculate_transformation. {'error_name': 'AssertionError', 'filename': './algorithm/three_dim/transformation_matrix_calculation.py', 'line': 28, 'func': '__init__', 'text': 'assert FootDigitizerParameters.is_angle_between_three_points_acceptable(self.__array_of_feature_points)'}"
              */
            } else if ( diff >= 5000 && diff < 7000) {
              response.data.status = 'processed'
            } else if ( diff >= 7000 ) {
              response.data.status = 'result_uploaded'
            }
        }

        if (response.statusCode === 200) {
            statusChange =   (prevStatus != response.data.status)
            prevStatus = response.data.status
            // Statusの変更をAppSyncに送信する
            if (response.data.status != 'error' && statusChange) {
                pollingState.message = response.data.status　　　　　　　　　　　　　　　　
                createAppSync( pollingState)
            }

            if ( response.data === void 0 || response.data.status === void 0 ) {
                return resolve(response)
            }
            if (response.data.status == 'result_uploaded' ) {
                //dispatchFunc(response.data.status )
                return resolve(response)
            } 

            if (response.data.status == 'error' ) {
                //dispatchFunc(response.data.status )
                console.log(" ERROR  ", response.data )
                return reject(new Error(response.data.errorDetail))
            }

            if (response.data.status == 'scanned' ) {
                dispatchFunc(response.data.status )
            } 

            setTimeout(checkCondition, interval, resolve, reject)
        } else if (response.statusCode < 500 && Date.now() < endTime) {
            setTimeout(checkCondition, interval, resolve, reject)
        } else {
            reject(new Error('ネットワークエラーが発生しました。'))
        }
    }
    return new Promise(checkCondition)
}