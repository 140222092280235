import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch, createDispatchHook } from 'react-redux';
import uuid from 'react-uuid';
//import {getItems , setScene, getFdcode, createAppSync,  pushGtm, updateTimer } from '../actions/index';
import  * as action from '../actions/index';
import { TweenMax } from "gsap"
import styled from 'styled-components';
import BackButton from './buttons/ButtonsA' 
import topBtn from '../assets/images/top_btn.svg';
import yajirushi from '../assets/images/topYajirushi.svg';
import BgImg from '../assets/images/bg_1.jpg';
import nextBtn from '../assets/images/next_btn.svg'

export default function Top() {

  const [error, setError] = useState(null);
  const [isDisabled, setStateDisabled] = useState(false)
  const [isGuidance, setStateGuidance] = useState(false)
  const [digitizer, setStateDigitizer] = useState("");
  const [sessionUuid, setSessiontUuid] = useState("");

  const s = useSelector(state => state);
  const items = useSelector(state => state.items);
  const dispatch = useDispatch();
  const TopBtnEl = useRef(null)
  const TopBtnImageEl = useRef(null)
  const NextBtnEl = useRef(null)
  const YajirushiEl = useRef(null)

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    dispatch({type: "SET_RETRY", retry:null})
    dispatch({type: "SET_ERROR", error:null})

    const f = async () => {
      action.pushSheet()
      try {
        const fdcode = await action.getFdcode(dispatch)
        setStateDigitizer(fdcode)
        action.getItems(fdcode, dispatch)

        // UUID生成
        const dateObj = new Date()
        const uuidDate = action.dateFormat.format(dateObj, 'yyyyMMddhhmmss')
        const serialDate = action.dateFormat.format(dateObj, 'yyyyMMdd')
        const key = 'serialnumber'
        const keyDate = 'keydate'
        let number = 0

        if (localStorage.getItem(key)) {
          if (localStorage.getItem(keyDate) && localStorage.getItem(keyDate) == serialDate ) {
            number = localStorage.getItem(key);
          }
        }        
        number++

        localStorage.setItem(key, number)
        localStorage.setItem(keyDate, serialDate)

        const serial = ( '0000' + number ).slice( -4 );
        const wkSessionUuid = `${fdcode}-${uuidDate}-${serial}`
        setSessiontUuid(wkSessionUuid)
        dispatch({type: 'SET_UUID', uuid: wkSessionUuid })
       
        const input = { store_code: fdcode , state: "STANDBY"};
        console.log(input)
        action.createAppSync(input)

        dispatch( { type: 'SET_RESETGTM'})
        dispatch({type: 'SET_GTM', store_code:fdcode, session_id: wkSessionUuid })
        const gtmData = {  
        'event': 'statepush', 
        'store_code': fdcode, 
        'session_id': wkSessionUuid, 
        'state': 'STANDBY',
        'operation': "READ",
        'foot_uuid': "",
        'shoe_id': "",
        'fit': null,
        'recommended_size': "",
        'created_at': action.dateFormat.format(new Date(), 'yyyy/MM/dd hh:mm:ss')
        }
        window.dataLayer.push(gtmData)
        //action.pushSheet(gtmData)
        action.pushLogLocalStorage(gtmData)
      } catch (e) {
        setStateDisabled(true)
        dispatch({type: "SET_ERROR", error:e.message})
        console.log(e)
        // 初期処理のエラーはAppsyncに送らない
        // TODO エラーメッセージをGTMにも送る
        const gtmData = { 
         'event': 'statepush', 
         'store_code': "", 
         'session_id': "", 
         'state': 'ERROR',
         'operation': "INIT",
         'foot_uuid': "",
         'shoe_id': "",
         'fit': null,
         'recommended_size': "",
         'created_at': action.dateFormat.format(new Date(), 'yyyy/MM/dd hh:mm:ss')
        }
        window.dataLayer.push(gtmData)
        //action.pushSheet(gtmData)
        action.pushLogLocalStorage(gtmData)
      }
    }
    //TweenMax.to(TopButton.current, 2, { rotation:360, transformOrigin:"50% 50%", repeat: -1, ease: "power2.inOut"})
    TweenMax.from(TopBtnImageEl.current, 2, { repeatDelay: 5 , scale: 1.1, ease: "elastic.out(1, 0.2)", repeat: -1})
    //TweenMax.to(YajirushiEl.current, 1, { repeatDelay: 2, y: -200, ease: "power2.inOut", repeat: -1})


    f()
    return () => {
      // Unmount時の処理を記述
      TopBtnImageEl.current = true
      YajirushiEl.current = true
    };
  }, [])

  function hundleNext() {
    if ( !items[2]) {
      dispatch({type: "SET_ERROR", error: "通信状況を確認してください"})
      return 
    }  
    setStateDisabled(true)
    TweenMax.to(TopBtnEl.current, 1, { scale: 4, ease: "power2.inOut", onComplete : function(){

      action.createAppSync({ store_code: digitizer , state: "GUIDE_01", shoe_model: items[2].model })
      action.pushGtm(s.gtm, { state: "GUIDE_01", operation: "NEXT", shoe_id: items[2].model} )
      dispatch({ type:"SET_SELECTED", selected:items[2].model })
      action.setScene("Guide1", dispatch)
    }})
    TweenMax.to(TopBtnEl.current, 0.8, { opacity: 0, ease: "power2.inOut"})




    //TweenMax.to(TopBtnEl.current, 1, { top: -100, ease: "power2.inOut", onComplete : function(){ }})

    action.updateTimer(s.timer, s.gtm, dispatch)
    action.sendSlackMessage(digitizer,sessionUuid)
  }

  
  function hundleGuidance() {
    setStateDisabled(true)
    setStateGuidance(true)
    action.createAppSync({ store_code: digitizer , state: "STANDBY"})
    action.pushGtm(s.gtm, { state: "STANDBY", operation: "START"} )
    action.updateTimer(s.timer, s.gtm, dispatch)
    TweenMax.to(TopBtnEl.current, 0.5, { scale: 0, ease: "power2.inOut"})
    TweenMax.to(NextBtnEl.current, 0.5,{ scale: 1, delay: 0.5, ease: "power2.inOut"})
    setStateDisabled(false)
  }

  const hundleChangeScene = (scene) => () => {  
    try {
      setStateDisabled(true)
      let input = { state: "STANDBY", store_code: digitizer}

      action.setScene("Top",  dispatch)
      action.createAppSync(input)

      setStateDisabled(false)
      setStateGuidance(false)
      action.pushGtm(s.gtm, { state: "STANDBY", operation: "BACK"} )
      action.updateTimer(s.timer, s.gtm, dispatch)
    } catch(e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }


  return (
    <Root img={BgImg}>
        <Start ref={TopBtnEl}>

            <button ref={TopBtnImageEl} type="button" disabled={isDisabled}  onTouchStart={hundleNext}>
<Push>PUSH！</Push>
            </button>

        { /** 
        <img src={yajirushi} ref={YajirushiEl} width='100px' />
         * 
            <button ref={TopBtnImageEl} type="button" disabled={isDisabled}  onTouchStart={hundleNext}>
              <img src={topBtn}  width='90%' />
            </button>
        */}
        </Start>
        <Next ref={NextBtnEl}>
        <button type="button" disabled={isDisabled} onTouchStart={hundleNext}>
          <img src={nextBtn}  width='90%' />
        </button>
        </Next>
      { isGuidance && 
        <TopButton onTouchStart={hundleChangeScene}  scene={"Top"} >TOPに戻る</TopButton>
      }

    </Root>
  );
}

const Root = styled.div`
  position: relative;
  height: 100vh; /* 全画面表示 */
  background: url(${props => props.img}) no-repeat center;
  background-size: cover;
  z-index: 0;
  &::before {
    content: "";
    background: inherit;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(5px);
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
  }
`

const Push =  styled.div`
  width: 500px;
  padding: 5rem 4rem;
  color: #fff;
  border-radius: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#ff3cac), color-stop(#562b7c), to(#2b86c5));
  background-image: -webkit-linear-gradient(left, #ff3cac 0%, #562b7c 50%, #2b86c5 100%);
  background-image: linear-gradient(90deg, #ff3cac 0%, #562b7c 50%, #2b86c5 100%);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  transform: skew(-15deg);
  -webkit-transform: skew(-15deg);
  font-size: 80px;
`


const Text = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 2.5em;
  width: 100%;
`

const Start = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  width: 100%;
  text-align: center;
`

const Next = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0,0);
  -webkit-transform: translateY(-50%) translateX(-50%) scale(0,0);
  width: 100%;
`
const TopButton = styled(BackButton)`
  position: absolute;
  top: 10px;
  left: 15px;
`