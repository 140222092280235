import { AWSAppSyncRealTimeProvider } from '@aws-amplify/pubsub';
import { createStore } from 'redux';


const INITIAL_STATE = {
  scene: "Top",
  error: null,
  digitizer: null,
  retry: null,
  uuid: null,
  measurement: null,
  jissize: null,
  recommend: {
    fittingRate: null,
    size: null
  },
  file: null,
  footuuid: null,
  scanStatus: null,
  items: [],
  selected: null,
  ec: null,
  timer: null,
  gtm: {
    'event': 'statepush', 
    'store_code': "", 
    'session_id': "", 
    'state': "",
    'operation':"",
    'foot_uuid': "",
    'shoe_id': "",
    'fit': null,
    'recommended_size': "",
    'created_at': null
  }
};

function courses(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_COURSE':
      return { ...state, data: [...state.data, action.title] };
    case 'SET_GTM':
      return { ...state,  gtm: { ...state.gtm, store_code: action.store_code, session_id: action.session_id }  };
    case 'SET_RESETGTM':
      return { ...state,  gtm: {
          'event': 'statepush', 
          'store_code': "", 
          'session_id': "", 
          'state': "",
          'operation':"",
          'foot_uuid': "",
          'shoe_id': "",
          'fit': null,
          'recommended_size': "",
          'created_at': null
       }  };
    case 'SET_SCENE':
      return { ...state, scene:  action.scene };
    case 'SET_TIMER':
      return { ...state, timer:  action.timer };
    case 'SET_DIGITIZER':
      return { ...state, digitizer:  action.digitizer };
    case 'SET_UUID':
      return { ...state, uuid: action.uuid };
    case 'SET_SELECTED':
      return { ...state, selected: action.selected };
    case 'SET_ITEM':
      return { ...state, items: action.items };
    case 'SET_FOOTUUID':
      return { ...state, footuuid: action.footuuid };
    case 'SET_RETRY':
      return { ...state, retry:  action.retry };
    case 'SET_MEASUREMENT_STATUS':
      return { ...state, scanStatus:  action.scanStatus };
    case 'REGISTER_MEASUREMENT':
      console.log(action.payload)
      return { ...state, ...action.payload };
    case 'SET_JISSIZE':
      return { ...state, jissize: {...action.jis}};
    case 'SET_RECOMMEND':
      return { ...state, recommend:{ fittingRate: action.fittingRate, size: action.size}};
    case 'SET_EC':
      console.log(action.ec)
      return { ...state, ec:  action.ec };
    case 'SET_ERROR':
      return { ...state, error: action.error};
    default:
      return state;
  } 
}

const store = createStore(courses);

export default store;