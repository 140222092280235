import humps from 'humps'
import config from './config';

const FDAPI=`${config.FDAPI[config.STAGE]}`
const API=`${config.API[config.STAGE]}`
const RECOMMENDAPI=`${config.RECOMMENDAPI[config.STAGE]}`
const SLACKWEBHOOK=`${config.SLACKWEBHOOK}`

const fdApiHandleErrors = (res) => {
    if (res.ok) {
      return res;
    }
  
    switch (res.status) {
      case 400: throw Error('INVALID_TOKEN');
      case 401: throw Error('UNAUTHORIZED');
      case 500: throw Error('INTERNAL_SERVER_ERROR');
      case 502: throw Error('BAD_GATEWAY');
      case 404: throw Error('NOT_FOUND');
      default:  throw Error('UNHANDLED_ERROR');
    } 
}
  
export function fdApiFetchWithErrorHandling (endpoint, options) {
    let opt = {...options}
    opt['headers'] = {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json'
    }
    if ( "body" in opt ) opt['body'] = JSON.stringify(humps.decamelizeKeys(opt["body"]))

    return fetch(`${FDAPI}${endpoint}`, opt)
      // 1. ネットワーク周りなどのリクエスト以前の段階でのエラーを処理する
      .catch((e) => { throw Error(e); })
      // 2. サーバサイドで発行されたエラーステータスを処理する
      .then(fdApiHandleErrors)
      // 3. 以上2つをパスした正常なレスポンスからJSONオブジェクトをパースする
      .then(res => res.json())
      .then( data =>  {
        return new Promise(resolve => resolve(humps.camelizeKeys(data)) ) 
      });
}

export function recommendApiFetchWithErrorHandling (endpoint, options) {
  let opt = {...options}
  opt['headers'] = {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json'
  }
  if ( "body" in opt ) opt['body'] = JSON.stringify(humps.decamelizeKeys(opt["body"]))
  
  return fetch(`${RECOMMENDAPI}${endpoint}`, opt)
    // 1. ネットワーク周りなどのリクエスト以前の段階でのエラーを処理する
    .catch((e) => { throw Error(e); })
    // 2. サーバサイドで発行されたエラーステータスを処理する
    .then(fdApiHandleErrors)
    // 3. 以上2つをパスした正常なレスポンスからJSONオブジェクトをパースする
    .then(res => res.json())
    .then( data => { 
      let res = { ...data }
      return  new Promise(resolve => resolve(humps.camelizeKeys(res)) ) 
    });
}

export function slackFetchWithErrorHandling (options) {
  let opt = {...options}
  opt['headers'] = {
      'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8",
      Accept: 'application/json'
  }
  if ( "body" in opt ) opt['body'] = JSON.stringify(opt["body"])

  return fetch(`${SLACKWEBHOOK}`, opt)
    // 1. ネットワーク周りなどのリクエスト以前の段階でのエラーを処理する
    .catch((e) => { throw Error(e); })
    // 2. サーバサイドで発行されたエラーステータスを処理する
    .then(fdApiHandleErrors)
    .then( data =>  {
      return new Promise(resolve => resolve(humps.camelizeKeys(data)) ) 
    });
}

const handleErrors = (res) => {
    if (res.ok) {
      return res;
    }
    switch (res.status) {
      case 400: throw Error('INVALID_TOKEN');
      case 401: throw Error('UNAUTHORIZED');
      case 500: throw Error('INTERNAL_SERVER_ERROR');
      case 502: throw Error('BAD_GATEWAY');
      case 404: throw Error('NOT_FOUND');
      default:  throw Error('UNHANDLED_ERROR');
    } 
}
  
export function fetchWithErrorHandling (endpoint, options) {
    let opt = {...options}
    opt['headers'] = {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json'
    }
    if ( "body" in opt ) opt['body'] = JSON.stringify(humps.decamelizeKeys(opt["body"]))

    return fetch(`${API}${endpoint}`, opt)
      // 1. ネットワーク周りなどのリクエスト以前の段階でのエラーを処理する
      .catch((e) => { throw Error(e); })
      // 2. サーバサイドで発行されたエラーステータスを処理する
      .then(handleErrors)
      // 3. 以上2つをパスした正常なレスポンスからJSONオブジェクトをパースする
      .then(res => res.json())
      .then( data =>  new Promise(resolve => resolve(humps.camelizeKeys(data)) ) );
}

async function subscribe(endpoint, options) {
    let opt = {...options}
    opt['headers'] = {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json'
    }
    if (options.body) opt['body'] = humps.decamelizeKeys(options.body)

    let response = await fetch(`${API}${endpoint}`, options);
  
    if (response.status == 502) {
      // Status 502 is a connection timeout error,
      // may happen when the connection was pending for too long,
      // and the remote server or a proxy closed it
      // let's reconnect
      await subscribe();
    } else if (response.status != 200) {
      // An error - let's show it
      //showMessage(response.statusText);
      // Reconnect in one second
      await new Promise(resolve => setTimeout(resolve, 1000));
      await subscribe();
    } else {
      // Get and show the message
      //let message = await response.text();
      //showMessage(message);
      // Call subscribe() again to get the next message
      await subscribe();
    }
  }