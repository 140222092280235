export default {
  color: {
    white: '#fff',
    black: '#000',
    yellow: '#fff000',
    grey: '#f3f3f3',
    grey2: '#707070',
    grey3: '#A5A5A5',
    grey4: '#d6d6d6',
    grey5: '#444444',
    grey6: '#dddddd',
    grey7: '#e9e9e9',
    disable: '#DDDBDB',
    key: '#EA4122',
  },
  font: {
    regular: 'Hiragino Kaku Gothic ProN',
    bold: 'HiraKakuPro-W6',
    english: 'Helvetica Neue',
    englishBold: 'HelveticaNeue-Bold'
  }
}
