import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { setScene, getFootUuid, scan, watchMeasurement, getJisSize, updateAppSync, createAppSync, updateTimer, pushGtm, getRecommend} from '../actions/index'
import * as action from '../actions/index'
import { formatSize} from '../helpers/number'
import { TweenMax } from "gsap"

import styled from 'styled-components'
import circle from '../assets/images/circle.svg'
import BackButton from './buttons/ButtonsA' 
import config from '../config';

import BgImg from '../assets/images/bg_1.jpg'

export default function Mesurement() {

  const [isDisabled, setStateDisabled] = useState(false)

  const s = useSelector(state => state);
  const digitizer = useSelector(state => state.digitizer);
  const uuid = useSelector(state => state.uuid);
  const selectedModel = useSelector(state => state.selected)
  const gtm = useSelector(state => state.gtm)
  const items = useSelector(state => state.items)
  const timer = useSelector(state => state.timer)
  const dispatch = useDispatch();
  const circleEl = useRef(null)
  const LoadEl = useRef(null)

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() =>  {

    const f = async () => {


      try {
        const footUuid =  await action.getFootUuid(s.digitizer, dispatch)
        await action.scan(footUuid)

        let polingStateInput = { 
          state: "MEASURING", 
          store_code: s.digitizer,
          shoe_model: selectedModel,
          message: ""
        }
        const meas =  await action.watchMeasurement(footUuid, polingStateInput, dispatch)
        await action.getJisSize(footUuid, dispatch)

        // Recommend
        let size = meas.left.footMeasurement.footLength
        let width = meas.left.footMeasurement.footBoundingWidth
        if (size <  meas.right.footMeasurement.footLength ) {
          size = meas.right.footMeasurement.footLength
          width = meas.right.footMeasurement.footBoundingWidth
        }

        const selectItem = items.filter(item => item.model == selectedModel)
        const recommendSize = await action.getRecommend(size, width, selectItem[0].productNumber, dispatch )
        
        if( recommendSize != 'no_matches' ) {
          //let variant = selectItem[0].size.filter( s =>  formatSize(s.jp)  == recommendSize )
          const urlSizeParam = recommendSize * 10
          // ECURL
          dispatch({type: "SET_EC", ec: `${config.EC}?model=${selectItem[0].model}&size=${urlSizeParam}`}) 
        }
      } catch (e) {
        console.log("== ERROR MESSAGE ==", e.message)
        if (e.sendstate) {
           action.createAppSync({ 
              state: "ERROR", 
              store_code : digitizer, 
              error_code : e.code, 
              message: e.message,
              shoe_model: selectedModel})
          dispatch({type: "SET_RETRY", retry:e.message})
        } else {
          dispatch({type: "SET_ERROR", error:e.message})
        }
        action.pushGtm(gtm, { state: "ERROR", operation: "MEASUREMENT", shoe_id : selectedModel } )
      }

      // 結果ページへ
      action.updateTimer(timer, gtm, dispatch)
      action.setScene("Result", dispatch)
    }

    TweenMax.to(LoadEl.current, 0.5,{ scale: 1, delay: 0, ease: "power2.inOut"})
    TweenMax.to(circleEl.current, 2, { rotation:360, transformOrigin:"50% 50%", repeat: -1, ease: "power2.inOut"})
    f()

  }, [])

  const hundleChangeScene = (scene) => () => {
    setStateDisabled(true)
    let input = { state: "STANDBY", store_code: digitizer}
    action.createAppSync(input)
    action.setScene(scene, dispatch)
    action.pushGtm(gtm, { state: input.state, operation: "RESET", shoe_id : selectedModel } )
    action.updateTimer(timer, gtm, dispatch)
    setStateDisabled(false)
  }


  return (
    <Root img={BgImg}>
      <TopButton disabled={isDisabled}  onTouchStart={hundleChangeScene}  scene={"Top"} >TOPに戻る</TopButton>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  height: 100vh; /* 全画面表示 */
  width: 100vw; /* 全画面表示 */
  background: url(${props => props.img}) no-repeat center;
  background-size: cover;
  z-index: 0;
  &::before {
    content: "";
    background: inherit;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(5px);
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
  }
`
const Measuring = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0,0);;
  -webkit- transform: translateY(-50%) translateX(-50%) scale(0,0);
`
const Inner = styled.div`
  position: relative;
  width: 450px;
`
const Text = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  width: 100%;
  color: ${({ theme }) => theme.color.white};
  text-align: center;
`
const MainText = styled.p`
  font-weight: bold;
  font-size: 6.0em;
  color: ${({ theme }) => theme.color.grey2};
`
const SubText = styled.p`
  color: ${({ theme }) => theme.color.key};
  font-size: 2.0em;
`

const TopButton = styled(BackButton)`
  position: absolute;
  top: 10px;
  left: 15px;
`


const Header = styled.ul`
  position: absolute;
  top: 0px;
  left: 0px;
  background: ${({ theme }) => theme.color.grey};
  width: 100%;
  height: 190px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
  align-items: center; /* 縦方向中央揃え */
  -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
  justify-content: center; /* 横方向中央揃え */
  > li  {
    width: 200px;

  }
`

const Stepon = styled.dl`
  text-align: center;
    > dt {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.key};
      text-align: center;
      color: #fff;
      margin: auto;
      font-size: 1.8em;
      font-weight: bold;
    }
    > dd {
      margin-top: 5px;
      font-size: 1.5em;
      font-weight: bold;


    }
`
const Stepoff = styled.dl`
  text-align: center;
    > dt {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.grey7};
      text-align: center;
      color: #fff;
      margin: auto;
      font-size: 1.8em;
      font-weight: bold;
    }
    > dd {
      color: ${({ theme }) => theme.color.grey4};
      margin-top: 5px;
      font-size: 1.5em;
      font-weight: bold;

    }
`
