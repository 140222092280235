import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createCommand, updateCommand } from '../graphql/mutations'
//import {setScene, updateAppSync, createAppSync , pushGtm, updateTimer} from '../actions/index'
import * as action from '../actions/index'
import Button from './buttons/ButtonsA'   
import ItemButton from './buttons/itemDetailButton'   
import SceneButton from './buttons/ButtonsD'
import Thumbnail from './item/ItemThumbnail'   
import styled from 'styled-components'
import { TweenMax } from "gsap"

import itemBtn from '../assets/images/itemButton.svg'
import sozaiBtn from '../assets/images/sozaiButton.svg'
import snapBtn from '../assets/images/snapButton.svg'
import reviewBtn from '../assets/images/reviewButton.svg'
import BgImg from '../assets/images/bg_1.jpg';

export default function Tryon() {
  const [isDisplay, setDisplay] = useState(false) 
  const [isDisabled, setDisabled] = useState(false) 
  const [isMeasBtnDisabled, setMeasBtnDisabled] = useState(true) 
  const [tab, setTab] = useState(null) 
  const [selected, setSelected] = useState(null) 
  const [grid12, setGrid12Status] = useState(false) 
  const [products, setProducts] = useState([]) 
  const dispatch = useDispatch();
  const commandid = useSelector(state => state.commandid);
  const items = useSelector(state => state.items);
  const selectedModel = useSelector(state => state.selected);
  const digitizer = useSelector(state => state.digitizer);
  const gtm = useSelector(state => state.gtm);
  const timer = useSelector(state => state.timer);

  const TextEl = useRef(null)

  useEffect(() => {
    setSelected(selectedModel)
    setTimeout(() => { 
      setDisplay(false); 
      TweenMax.to(TextEl.current, 0.5,{ scale: 0, delay: 0, ease: "power2.inOut",
      onComplete : function(){
        setDisplay(true)
        setTimeout(() => { 
          // モデルを変更せずとも、15S後にはボタンを有効にする
          setMeasBtnDisabled(false)
        }, 15000)
      }
    })
    } , 6000 )

    setGrid12Status(items.length > 4 )

    let productNumber = null 
    let productlist =  []
    let productslist = []
    for ( const v in items ) {
      if ( productNumber != null && items[v].productNumber != productNumber) {
        productslist.push(productlist)
        productlist = []
      }
      productlist.push(items[v])
      productNumber = items[v].productNumber
    }
    productslist.push( productlist)
    setProducts(productslist)
    



  }, [])

  const hundleSelectModel = (model) => () => {
    if(isDisabled) return
    if(selected == model ) return
    setDisabled(true)
    setMeasBtnDisabled(true)
    try {
      const input = { 
        state: "TRYON", 
        store_code: digitizer,
        shoe_model:  model }

      action.createAppSync(input)
      setSelected(model)
      dispatch({ type:"SET_SELECTED", model })
      action.pushGtm(gtm, { state: input.state, operation: "SELECT", shoe_id : model} )
      action.updateTimer(timer, gtm, dispatch)
      setDisabled(false)
      setMeasBtnDisabled(false)
    } catch(e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }

  const hundleChangeScene = (scene) => () => {
    setDisabled(true)
    setMeasBtnDisabled(true)
    setTab(null)
    let gtm_operation = "BUTTON"
    try{
      const input = { 
        state: "ITEM", 
        store_code: digitizer
        }
      switch (scene) {
        case "Top":
          input.state = "STANDBY"
          gtm_operation = "RESET"
          break;
        case "Guide1":
          input.state = "GUIDE_01"
          gtm_operation = "NEXT"
          input.shoe_model =  selected
          break;
        case "Tryon":
          input.state = "TRYON"
          input.shoe_model = selected 
          break;
        case "Item":
        defualt:
          input.shoe_model = selected 
          break;

      }

      action.createAppSync(input)

      action.setScene(scene, dispatch)
      dispatch({ type:"SET_SELECTED", selected: selected})
      action.pushGtm(gtm, { state: input.state, operation: gtm_operation, shoe_id :  input.shoe_model ? input.shoe_model : null } )
      action.updateTimer(timer, gtm, dispatch)
      setDisabled(false)
      setMeasBtnDisabled(false)
    }catch(e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }
  const hundleChangeTab = (tab) => () => {
    setDisabled(true)
    try {
      const input = { 
        state: tab, 
        store_code: digitizer,
        shoe_model:selected
      }
      action.createAppSync(input)
      setTab(tab)
      action.pushGtm(gtm, { state: input.state, operation: "SELECT", shoe_id : selected} )
      action.updateTimer(timer, gtm, dispatch)
      setDisabled(false)
    } catch (e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }

  return (
    <Root img={BgImg}>
      { !isDisplay && 
      <Text>
        正面のディスプレイをご覧ください
      </Text>

      }
      {/* 
      { isDisplay && 
      <Header>
        <li>
          <Stepon>
            <dt>1</dt>
            <dd>デザインを選ぶ</dd>
          </Stepon>
        </li>
        <li>
          <Stepoff>
            <dt>2</dt>
            <dd>サイズを調べる</dd>
          </Stepoff>
        </li>
        <li>
          <Stepoff>
            <dt>3</dt>
            <dd>購入する</dd>
          </Stepoff>
        </li>
      </Header>
      }
      */}


      { isDisplay && 
      <Items>
      { products.map((product, productindex ) => {
        return (
          <Product key={productindex}>

      <ModelTitle grid12={(grid12)}>
        <ModelName>{product[0].maker}/{product[0].ecProductName}</ModelName>
        <ModelPrice>¥{product[0].price}</ModelPrice>
      </ModelTitle>
      <List grid12={(grid12)}>
      {product.map((item, i ) => {
          return (
            <li key={i} >
              <Thumbnail
                isDisabled={true}
                src={item.thumbnail}
                name={item.name}
                selected={(selected === item.model)}
                onSelect={hundleSelectModel(item.model)}
                grid12={grid12}
              />
            </li>
          )
        })}
      </List>
          </Product>
      )}
      )}

      {tab !== null &&
      <div>

        <Close />
        <CloseButton 
        isDisabled={isDisabled}
        color="#000"
        onTouchStart={hundleChangeScene}  scene={"Tryon"} >デザインを選ぶ</CloseButton>
      </div>
      }
      
      

      </Items>
      }




      { isDisplay && 
      <Footer>
       <MeasurementButton 
          isDisabled={isMeasBtnDisabled}
          onTouchStart={hundleChangeScene} scene={"Guide1"}><p>足計測に進む<br /><span>ぴったりな靴サイズを見つけよう！</span></p></MeasurementButton>
      </Footer>
      }

      <TopButton 
        isDisabled={isDisabled}
        onTouchStart={hundleChangeScene}  scene={"Top"} >TOPへもどる</TopButton>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  width: 100vw; /* 全画面表示 */
  height: 100vh; /* 全画面表示 */
  background: url(${props => props.img}) no-repeat center;
  background-size: cover;
  z-index: 0;
  &::before {
    content: "";
    background: inherit;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(5px);
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
  }
`
const Text = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 2.5em;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  color: #fff;
`

const Header = styled.ul`
  position: absolute;
  top: 0px;
  left: 0px;
  background: ${({ theme }) => theme.color.grey};
  width: 100%;
  height: 190px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
  align-items: center; /* 縦方向中央揃え */
  -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
  justify-content: center; /* 横方向中央揃え */
  > li  {
    width: 200px;

  }
`
const Footer = styled.div`
  position: absolute;
  bottom 30px;
  left: 0px;
  width: 100%;
  height: 140px;
`
const Stepon = styled.dl`
  text-align: center;
    > dt {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.key};
      text-align: center;
      color: #fff;
      margin: auto;
      font-size: 1.8em;
      font-weight: bold;
    }
    > dd {
      margin-top: 5px;
      font-size: 1.5em;
      font-weight: bold;


    }
`
const Stepoff = styled.dl`
  text-align: center;
    > dt {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.grey7};
      text-align: center;
      color: #fff;
      margin: auto;
      font-size: 1.8em;
      font-weight: bold;
    }
    > dd {
      color: ${({ theme }) => theme.color.grey4};
      margin-top: 5px;
      font-size: 1.5em;
      font-weight: bold;

    }
`

const ModelTitle = styled.h3`
  margin-top: 10px;
  margin-left: ${({ grid12 }) => grid12 ? "20px" : "40px" };
  color: #fff;
`
const ModelName = styled.p`
display: inline;
  font-size: 1.3em;
  font-weight: bold;
  color: #fff;

`
const ModelPrice = styled.p`
  display: inline;
  margin-left: 40px;
  font-size:1.0em;
  color: #fff;
`
const Product = styled.div`
  margin-bottom: 50px;
`
const Items = styled.div`
  position: absolute;
  width: 100%;
  top: 100px;
`

const Close = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #000;
  opacity: 0.6;
  width: 100%;
  height: 600px;
`


const List = styled.ul`
  margin-top: 10px;
  display: grid;
  grid-template-columns: ${({ theme, grid12 }) => grid12 ? "1fr 1fr 1fr 1fr" : "1fr 1fr" };
  grid-template-rows: 1fr;
  grid-gap: ${({ theme, grid12 }) => grid12 ? "5px" : "25px" };
  text-align: center;
  margin: ${({ grid12 }) => grid12 ? "0 20px" : "0" }; 
  > li {
    margin: auto;
  }
`
const MeasurementButton = styled(SceneButton)`
  width: 90%;
  padding: 10px 10px
  color: #fff;
  border-radius: 0;
  border: solid 2px #000;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) skew(-15deg);
  -webkit-transform: translateX(-50%) translateY(-50%) skew(-15deg);
  > p {
    > span {
      font-size: 0.7em;
      font-weight: bold;
    }
  }
`

const Buttons = styled.ul`
  position: absolute;
  bottom: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
  align-items: center; /* 縦方向中央揃え */
  -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
  justify-content: center; /* 横方向中央揃え */
  > li {

  }

`

const TopButton = styled(Button)`
  position: absolute;
  top: 10px;
  left: 15px;
`
const CloseButton = styled(Button)`
  position: absolute;
top: 50%;
left: 50%;
transform: translateX(-50%) translateY(-50%);
-webkit-transform: translateX(-50%) translateY(-50%);
`