import * as React from 'react'
import styled from 'styled-components'


export default function ButtonC(props) {
    return (
        <ButtonStyle  
        disabled={props.isDisabled}
        className={props.className} 
        selected={props.selected} 
        onTouchStart={props.onTouchStart}>{props.children}</ButtonStyle>
    );
  }

const ButtonStyle = styled.button`
  width: 300px;
  height: 200px;
  line-height:200px;
  text-align:center;
  margin: 20px 20px;
  font-size: 2em;
  font-family: ${({ theme }) => theme.font.bold};
  background: ${({ theme, selected }) => selected ? theme.color.key : theme.color.grey3};
  padding: 0 27px;
  border-radius: 10px;
  color: ${({ theme }) => theme.color.white};
  &:disabled {
    background: ${({ theme }) => theme.color.disable};
  }
`
