import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import {setScene, createAppSync, pushGtm, updateTimer } from '../actions/index'
import * as action from '../actions/index'
import { TweenMax } from "gsap"

import config from '../config';

import styled from 'styled-components'
import nextBtn from '../assets/images/next_btn.svg'
import BackButton from './buttons/ButtonsA' 

export default function Intro() {
 
  const [isDisabled, setDisabled] = useState(false) 
  const [current, setCurrent] = useState("01") 
  const commandid = useSelector(state => state.commandid);
  const items = useSelector(state => state.items);
  const digitizer = useSelector(state => state.digitizer);
  const gtm = useSelector(state => state.gtm);
  const timer = useSelector(state => state.timer);
  const dispatch = useDispatch();
  const NextBtnEl = useRef(null)

  useEffect(() => {
    TweenMax.to(NextBtnEl.current, 0.5,{ scale: 1, delay: 0, ease: "power2.inOut"})
  }, [])

  const hundleChangeScene = (scene) => () => {  
    try {
      setDisabled(true)

      let input = { state: "TRYON", store_code: digitizer}
      if(current === "01") {
        input.state = "INTRO_02"
        setCurrent("02")
      } 

      switch(scene) { 
        case "Top":
          input.state = "STANDBY"
          break;
        case "Tryon":
        default:
          input["shoe_model"] = items[0].model
          dispatch({ type:"SET_SELECTED", selected:items[0].model })
          break;
      }

      TweenMax.to(NextBtnEl.current, 0.5, { scale: 0, ease: "power2.inOut",
      onComplete : function(){ 
        TweenMax.to(NextBtnEl.current, 0.5,{ scale: 1, delay: 0.1, ease: "power2.inOut"})
        console.log(input)

    
      }})

      if(  input.state != "INTRO_02" ){
        action.setScene(scene,  dispatch)
      } 

      action.createAppSync(input)

      setDisabled(false)
      action.pushGtm(gtm, { state: input.state, operation: "NEXT", shoe_id : input["shoe_model"] ? input["shoe_model"] : null} )
      action.updateTimer(timer, gtm, dispatch)






    } catch(e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }

  return (
    <Root>
      <Next ref={NextBtnEl}>
          <button type="button" disabled={isDisabled} onTouchStart={hundleChangeScene('Tryon')}>
            <img src={nextBtn} width= "90%"/>
          </button>
      </Next>

      {/** TOPBUTTON */}
      <TopButton onTouchStart={hundleChangeScene}  scene={"Top"} >TOPに戻る</TopButton>
    </Root>
  );
}


const Root = styled.div`
  position: relative;
  height: 100vh; /* 全画面表示 */
  widtg: 100vw; /* 全画面表示 */
`
const Next = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%)  scale(0,0);
  -webkit-transform: translateY(-50%) translateX(-50%)  scale(0,0);
  width: 100%;
`
const TopButton = styled(BackButton)`
  position: absolute;
  top: 10px;
  left: 15px;
`