import * as React from 'react'
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'
import checkPng from '../../assets/images/check.png'


export default function ItemThumbnail(props) {
    const selected = props.selected
    return (
        <ThumbStyle 
            className={props.className} onTouchStart={props.onSelect}>
            <img src={props.src} height="180px" />

          <Transition
            from={{ transform: 'scale(0)' }}
            enter={{ transform: 'scale(1)' }}
            leave={{ transform: 'scale(0)' }}
            items={selected}
          >
            {seleced =>
              seleced &&
              (props => (
                <CheckIcon
                  style={props}
                  src={checkPng}
                  width={44}
                  height={44}
                />
              ))
            }
          </Transition>
          <Name selected={selected} grid12={props.grid12}>試着する</Name>


        </ThumbStyle>
    );
  }

const ThumbStyle = styled.div`
  position: relative;
/*
background: ${({ theme, isDisabled }) => isDisabled ? theme.color.key: theme.color.white};
*/
`

const CheckIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -22px 0 0 -22px;
  pointer-events: none;
`

const Color = styled.div`
  position: absolute;
  top: 30px;
  left: 10px;
  font-size: 1.2em;
  font-size: ${({ grid12 }) => grid12 ? "1.0em": "1.2em"};
  font-weight: bold;
  color: ${  ({ theme }) => theme.color.white} ;
`

const Name = styled.div`
  background: ${({ theme, selected }) => selected? theme.color.black: theme.color.white};
  color: ${({ theme, selected }) => selected? theme.color.white: theme.color.black};
  border-radius: 0px;
  border: 2px solid #000;
  font-size: ${({ grid12 }) => grid12 ? "1.3em": "1.8em"};
  font-weight: bold;
  margin: 0 auto 0;
  width: ${({ grid12 }) => grid12 ? "160px": "200px"};
  padding: 5px;
`
