/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCommand = /* GraphQL */ `
  subscription OnCreateCommand($store_code: String!) {
    onCreateCommand(store_code: $store_code) {
      id
      store_code
      state
      shoe_model
      shoe_size
      shoe_ec_url
      foot_model
      foot_length_right
      foot_width_right
      foot_heel_width_right
      foot_instep_height_right
      foot_length_left
      foot_width_left
      foot_heel_width_left
      foot_instep_height_left
      fitting_rate
      error_code
      message
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCommand = /* GraphQL */ `
  subscription OnUpdateCommand($store_code: String!) {
    onUpdateCommand(store_code: $store_code) {
      id
      store_code
      state
      shoe_model
      shoe_size
      shoe_ec_url
      foot_model
      foot_length_right
      foot_width_right
      foot_heel_width_right
      foot_instep_height_right
      foot_length_left
      foot_width_left
      foot_heel_width_left
      foot_instep_height_left
      fitting_rate
      error_code
      message
      createdAt
      updatedAt
    }
  }
`;
