import React, {Fragment, useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch, Provider } from "react-redux"
import { ThemeProvider } from 'styled-components'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import GlobalStyle from './components/GlobalStyle'
import theme from './theme'
import './App.css'
import store from './store'
import {ClearTimer } from './actions'

import  cron from 'node-cron'

import Top from './components/Top'
import Mesurement from './components/Mesurement';
import Intro from './components/Intro';
import Result from './components/Result';
import Tryon from './components/Tryon';
import Item from './components/Item';
import Guide1 from './components/Guide1';
import Guide2 from './components/Guide2';
import Ecqrcode from './components/Ecqrcode';
import ErrorDialog from './components/ErrorDialog';


Sentry.init({
  dsn: "https://c4f52bc2cdb44f5eb92b77aecbc5185a@o470372.ingest.sentry.io/5500885",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});


// リロード
cron.schedule('0 0 1 * * *', () => {
  window.location.reload(true)
});

function Router ()   {
  const scene = useSelector(state => state.scene);
  const error = useSelector(state => state.error);
  const timer = useSelector(state => state.timer);
  const dispatch = useDispatch()

  useEffect(() => {
    if(scene === "Top") {
      ClearTimer(timer, dispatch)
    }
    return () => {
      // Unmount時の処理を記述
    };
  }, [scene])

  return (
    <Fragment>
      {scene === "Top" &&  <Top /> }
      {scene === "Intro" &&  <Intro /> }
      {scene === "Tryon" &&  <Tryon /> }
      {scene === "Item" &&  <Item /> }
      {scene === "Guide1" &&  <Guide1 /> }
      {scene === "Guide2" &&  <Guide2 /> }
      {scene === "Mesurement" &&  <Mesurement /> }
      {scene === "Result" &&  <Result /> }
      {scene === "Ecqrcode" &&  <Ecqrcode /> }
      { error !== null &&  <ErrorDialog  error={error} /> }
    </Fragment>


  )
}

function App () {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <GlobalStyle />
        <Provider store={store}>
          <Router />
        </Provider>
      </Fragment>
    </ThemeProvider>
  );
}

export default App;
