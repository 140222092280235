export default {  
    STAGE: process.env.REACT_APP_BUILD_ENV || 'development',  
    MASTERJSON: {  
      development: 'https://s3-ap-northeast-1.amazonaws.com/dev.minimum-store/json/master.json',
      test: 'https://s3-ap-northeast-1.amazonaws.com/dev.minimum-store/json/master.json',
      staging: 'https://s3-ap-northeast-1.amazonaws.com/dev.minimum-store/json/master.json',
      production: 'https://s3-ap-northeast-1.amazonaws.com/dev.minimum-store/json/master.json'
    },  
    RECOMMENDAPI: {  
      development: 'https://x2nnkxkycg.execute-api.ap-northeast-1.amazonaws.com/test/v1/recommend',
      test: 'https://x2nnkxkycg.execute-api.ap-northeast-1.amazonaws.com/dev/v1/recommend',
      staging: 'https://x2nnkxkycg.execute-api.ap-northeast-1.amazonaws.com/dev/v1/recommend',
      production: 'https://x2nnkxkycg.execute-api.ap-northeast-1.amazonaws.com/dev/v1/recommend'
    },  
    API: {  
      development: 'https://test.api.flicfit.com/v2',
      test: 'https://test.api.flicfit.com/v2',
      staging: 'https://test.api.flicfit.com/v2',
      production: 'https://api.flicfit.com/v2'
    },  
    FDAPI: {  
      development: 'http://192.168.100.201:8000',  
      test: 'http://192.168.100.201:8000',  
      staging:  'http://192.168.100.201:8000',
      production:  'http://192.168.100.201:8000'
    },
    EC: 'https://x2nnkxkycg.execute-api.ap-northeast-1.amazonaws.com/production/v1/redirect-puma',
    SLACKWEBHOOK: 'https://hooks.slack.com/services/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',
    SHEETAPIKEY: 'AIzaSyDsOPFQblTTXr1spP5ycLT0h5LXlSgNFEE',
    POLLINGSETTINGS: {
      // タイムアウトミリ秒
      timeout: 10000,
      // 実行間隔ミリ秒
      interval: 1000
    },
    // 自動リフレッシュミリ秒
    REFRESHTIMER: 60000 * 3
  } 