import * as React from 'react'
import styled from 'styled-components'


export default function ButtonA(props) {
    return (
        <ButtonStyle  
          disabled={props.isDisabled}
          className={props.className} 
          onTouchStart={props.onTouchStart(props.scene)}>{props.children}</ButtonStyle>
    );
  }

const ButtonStyle = styled.button`
  font-size: 13px;
  font-family: ${({ theme }) => theme.font.bold};
  height: 50px;
  background: #fff;
  padding: 0 27px;
  border: solid 2px ${({ theme }) => theme.color.grey};
  color: #000;
  &:disabled {
    color: ${({ theme }) => theme.color.grey};
  }
`
