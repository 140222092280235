import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import API, { graphqlOperation } from '@aws-amplify/api';
import config from '../config'; 
//import {setScene, updateAppSync, createAppSync, pushGtm, updateTimer } from '../actions/index'
import * as action from '../actions/index'
import { formatSize} from '../helpers/number'
import { onCreateCommand } from '../graphql/subscriptions'

import BackButton from './buttons/ButtonsA'   
import Button from './buttons/ButtonsB' 
import styled from 'styled-components'

import buyBtn from '../assets/images/buy.svg'
import retryBtn from '../assets/images/retry.svg'
import nextBtn from '../assets/images/next_btn.svg'
import restartBtn from '../assets/images/restart.svg'
import reloadBtn from '../assets/images/reload.svg'
import BgImg from '../assets/images/bg_1.jpg'

import { TweenMax } from "gsap"

export default function Result() {

  const [isLoaded, setIsLoaded] = useState(false);
  const [isDisabled, setDisabled] = useState(false) 
  const [isDialog, setDialog] = useState(false) 
  const [isNextDisabled, setNextDisabled] = useState(true) 
  const [isRestart, setRestart] = useState(false) 
  const [isTopbuttonLeft, setTopButtonLeft] = useState(true) 
  const s = useSelector(state => state);
  const size = useSelector(state => state.jissize);
  const ec = useSelector(state => state.ec);
  const recommend = useSelector(state => state.recommend);
  const commandid = useSelector(state => state.commandid);
  const digitizer = useSelector(state => state.digitizer);
  const foot_uuid = useSelector(state => state.footuuid);
  const gtm = useSelector(state => state.gtm);
  const timer = useSelector(state => state.timer);
  const retry = useSelector(state => state.retry);
  const selectedModel = useSelector(state => state.selected)
  const dispatch = useDispatch();
  const NextBtnEl = useRef(null)
  const RestartBtnEl = useRef(null)

  useEffect(() => {
    // タイマーをOFFにして、結果表示用のタイマー（10分）をセットする
    action.resultTimer(timer, gtm,dispatch)
    const subscription = API.graphql(graphqlOperation(onCreateCommand, {store_code: digitizer})).subscribe({
      next: (eventData) => {
        if ( eventData.value.data.onCreateCommand.state == "PAYMENTSUCCESS" ) {
          // TOPボタンを表示
          setNextDisabled(true); 
          setRestart(true)
          TweenMax.to(RestartBtnEl.current, 0.5,{ scale: 1, delay: 0, ease: "power2.inOut"})
        } else if ( eventData.value.data.onCreateCommand.state == "PAYMENTFAIL" ) {
          // QR再表示
          //action.createAppSync({ store_code: digitizer, state: "RESULT" })
        }
      }
    })


    if ( retry === null  ) {
      let input = { 
          store_code: digitizer,
          state: "RESULT", 
          shoe_model: s.selected,
          shoe_size: recommend.size == "no_matches" ? `${recommend.size}` : `${recommend.size}cm`,
          shoe_ec_url: `${ec}`,
          foot_model: s.file,
          foot_length_right: `${formatSize(size.right.length)}cm`,
          foot_width_right: size.right.jisWidthJa,
          foot_heel_width_right: size.right.heelWidthJa,
          foot_instep_height_right: size.right.instepHeightJa,
          foot_length_left: `${formatSize(size.left.length)}cm`,
          foot_width_left: size.left.jisWidthJa, 
          foot_heel_width_left: size.left.heelWidthJa,
          foot_instep_height_left: size.left.instepHeightJa,
          fitting_rate: recommend.fittingRate
      }
    
      try {
        action.createAppSync(input)
        setTimeout(() => { 
          setNextDisabled(false); 
          TweenMax.to(NextBtnEl.current, 0.5,{ scale: 1, delay: 0, ease: "power2.inOut"})
        } , 15000 )

      } catch (e) {
        dispatch({type: "SET_ERROR", error:e.message})
      }
      action.pushGtm(s.gtm, { 
          state: input.state, 
          operation: "", 
          foot_uuid:foot_uuid, 
          fit: input.fitting_rate , 
          recommended_size: input.shoe_size, 
          shoe_id : input.shoe_model
      })
    }
    return () => subscription.unsubscribe()
  }, [])


  function updateGraphql (state) {
    let gtm_operation = "BUY"
    let input = { state: state, store_code: digitizer,  shoe_model: s.selected}

    if(input.state == "STANDBY") {
      gtm_operation = "RESET"
      delete input.shoe_model
    } else if(input.state == "GUIDE_01") {
      gtm_operation = "RETRY"
    }

    try { 
      action.createAppSync(input)
    } catch(e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
    action.pushGtm(gtm, { state: input.state, operation: gtm_operation } )
    action.updateTimer(timer, gtm, dispatch)
  }

  const hundleChangeScene = (scene) => () => {
    setDisabled(true)
    let state =  "QR"
    if(scene == "Top") { 
      state = "STANDBY"
    } 
    if(scene == "Guide1") { 
      state = "GUIDE_01"
    }
    updateGraphql(state)
    action.setScene(scene, dispatch)
    setDisabled(false)
  } 

  const hundleReload = () => () => {
    action.resultTimer(timer, gtm ,dispatch)
    action.createAppSync({ store_code: digitizer, state: "RESULT" })
  } 

  const hundleDialog = () => () => {
    setDialog(true)
  }


  return (
    <Root img={BgImg}>
      { retry !== null && 
      <Measuring retry={retry}>
        <Inner>
          <RetryButton disabled={isDisabled}  type="button" disabled={isDisabled} onTouchStart={hundleChangeScene('Guide1')}>
            <Push>再計測<br /><span>Retry</span></Push>
          </RetryButton>
        </Inner>
      </Measuring>
      }

      {/**
       * 
      { ( recommend.size != 'no_matches' && isNextDisabled) && 
        <TextSizeCheck>足サイズとおすすめの靴サイズを確認ください!</TextSizeCheck>
      }
       */}

      { (recommend.size != 'no_matches' && !isNextDisabled) && 
      <Measuring retry={retry}>

        {/**
        <Inner ref={NextBtnEl}>
          <BuyButton 
            type="button" 
            disabled={isDisabled} 
            onTouchStart={hundleReload()}>
            <Push>再読込<br /><span>Reload</span></Push>
          </BuyButton>
        </Inner>
        <TopButtonNoMatches onTouchStart={hundleDialog}>TOPに戻る</TopButtonNoMatches>
        */}

        <Inner ref={NextBtnEl}>
          <BuyButton 
            type="button" 
            disabled={isDisabled} 
            onTouchStart={hundleChangeScene('Top')}>
            <Push>TOPへ戻る<br /><span>Return to Top</span></Push>
          </BuyButton>
        </Inner>


        {/**
          { retry === null  && 
            <Text>サイズは、購入ページで変更が可能です。</Text>
          }
        */}
      </Measuring>
      }
      { (recommend.size != 'no_matches' && isRestart) && 
      <Measuring retry={retry}>
        <Inner ref={RestartBtnEl}>
          <BuyButton 
            type="button" 
            disabled={isDisabled} 
            onTouchStart={hundleChangeScene('Top')}>
            <Push>TOPへ戻る<br /><span>Return to Top</span></Push>
          </BuyButton>
        </Inner>
      </Measuring>
      }


      
      { recommend.size == 'no_matches' && 
      <NoMatches>
          <TextNoMatches>おすすめできるサイズが<br />見つかりませんでした。</TextNoMatches>
          <TopButtonNoMatches onTouchStart={hundleChangeScene}  scene={"Top"} >TOPに戻る</TopButtonNoMatches>
      </NoMatches>
      }
      { isTopbuttonLeft && 
        <TopButton onTouchStart={hundleChangeScene}  scene={"Top"} >TOPに戻る</TopButton>
      }

      {  isDialog && 
      <DialogRoot>

      <DialogWrapper>
        <dl>
          <dt>
            <i className="fas fa-exclamation-triangle" />
            <p>本当にTOPに戻りますか？？</p>
          </dt>
          <dd>
            <p>
              <DialogButton2 onTouchStart={() => setDialog(false)}>キャンセル</DialogButton2>
              <DialogButton onTouchStart={() => window.location.reload()}>TOPヘ</DialogButton>
            </p>
          </dd>
        </dl>
      </DialogWrapper>
      </DialogRoot>
      }
    </Root>
  );
}
const Root = styled.div`
  position: relative;
  height: 100vh; /* 全画面表示 */
  width: 100vw; /* 全画面表示 */
  background: url(${props => props.img}) no-repeat center;
  background-size: cover;
  z-index: 0;
  &::before {
    content: "";
    background: inherit;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(5px);
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
  }
`

const Push =  styled.div`
  width: 500px;
  padding: 5rem 4rem;
  color: #fff;
  border-radius: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#ff3cac), color-stop(#562b7c), to(#2b86c5));
  background-image: -webkit-linear-gradient(left, #ff3cac 0%, #562b7c 50%, #2b86c5 100%);
  background-image: linear-gradient(90deg, #ff3cac 0%, #562b7c 50%, #2b86c5 100%);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  transform: skew(-15deg);
  -webkit-transform: skew(-15deg);
  font-size: 80px;
`

const Buttons = styled.div`
  width: 100%;
  position: absolute;
  top: 100px;
  text-align: center;
`
const RetryButton = styled.button`
`
const Measuring = styled.div`
  position: absolute;
  top: ${({ theme, retry }) => (retry !== null)? "700px": "50%"};
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit- transform: translateY(-50%) translateX(-50%) ;
  width: ${({ theme, retry }) => (retry !== null)? "60%": "90%"};
  text-align: center;
`
const Inner = styled.div`
  position: relative;
`
const InfoText = styled.p`
  width: 100%;

`
const BuyButton = styled.button`
  padding: 0;
  margin-top: 100px;
  font-size: 3.0em;
`

const Text = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit- transform: translateX(-50%);
  width: 700px;
  color: ${({ theme }) => theme.color.grey2};
  font-size: 1.5em;
  text-align: center;
  bottom: 50px;
`
const MainText = styled.p`
  font-weight: bold;
  font-size: 6.0em;
  color: ${({ theme }) => theme.color.grey2};
`
const SubText = styled.p`
  color: ${({ theme }) => theme.color.key};
  font-size: 2.0em;
`

const NoMatches = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translateY(-50%) translateX(-50%);
-webkit- transform: translateY(-50%) translateX(-50%);
width: 100%;
text-align: center
`


const TextNoMatches = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.color.white};
  font-size: 3.0em;
  font-weight: bold;
  text-align: center;
`

const TopButtonNoMatches = styled(BackButton)`
  margin-top: 80px;
  width: 90%;
  font-size: 1.2em;
  border: 2px solid ${({ theme }) => theme.color.black};
`

const TopButton = styled(BackButton)`
  position: absolute;
  top: 10px;
  left: 15px;
`


const DialogRoot = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(0, 0, 0, 0.7);
z-index: 99999;
`

const DialogWrapper = styled.div`
width: 520px;
background-color: #fff;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 20px;
dl {
  text-align: center;
}
dt {
  color: ${({ theme }) => theme.color.grey5 };
  font-size: 20px;
  i {
    margin-right: 10px;
  }
}
p {
  margin: 20px 0;
}
`

const DialogButton = styled.button`
height: 50px;
min-width: 120px;
font-weight: bold;
margin: 0 20px;
color: ${({ theme }) => theme.color.black };
background-color: ${({ theme }) => theme.color.white };
border: 2px solid ${({ theme }) => theme.color.black };
`

const DialogButton2 = styled.button`
height: 50px;
min-width: 120px;
font-weight: bold;
margin: 0 20px;
color: ${({ theme }) => theme.color.white };
background-color: ${({ theme }) => theme.color.grey2 };
border: 2px solid ${({ theme }) => theme.color.black };
`

