import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import {setScene, updateAppSync, createAppSync, pushGtm,updateTimer } from '../actions/index'
import * as action  from '../actions/index'
import BackButton from './buttons/ButtonsA'   
import styled from 'styled-components'
import nextBtn from '../assets/images/next_btn.svg'
import BgImg from '../assets/images/bg_1.jpg';
import { TweenMax } from "gsap"

export default function Guide1() {
 
  const [isDisabled, setDisabled] = useState(false) 
  const [isNextDisabled, setNextDisabled] = useState(true) 
  const commandid = useSelector(state => state.commandid);
  const digitizer = useSelector(state => state.digitizer);
  const gtm = useSelector(state => state.gtm);
  const selectedModel = useSelector(state => state.selected);
  const timer = useSelector(state => state.timer);
  const dispatch = useDispatch();
  const NextBtnEl = useRef(null)

  useEffect(() => {
    
    dispatch({type: "SET_RETRY", retry:null})
    setTimeout(() => { 
      setNextDisabled(false); 
      TweenMax.to(NextBtnEl.current, 0.5,{ scale: 1, delay: 0, ease: "power2.inOut"})
    } , 6000 )

    return () => {
      // Unmount時の処理を記述
    };


  }, [])

  const hundleChangeScene = (scene) => () => {
    setDisabled(true)
    try {

      let gtm_operation = "NEXT"
      let input = { 
        state: "GUIDE_02", 
        store_code: digitizer,
        shoe_model: selectedModel
      }
      if(scene == "Top") { 
        input.state = "STANDBY"
        gtm_operation = "RESET"
        delete input.shoe_model
      } 
    
      TweenMax.to(NextBtnEl.current, 0.5, { scale: 4, ease: "power2.inOut",
      onComplete : function(){
        action.createAppSync(input)
        action.setScene(scene, dispatch)
        action.pushGtm(gtm, { state: input.state, operation: gtm_operation, shoe_id :  input.shoe_model ? input.shoe_model : null } )
        action.updateTimer(timer, gtm, dispatch)
        setDisabled(false)

      }
    })
    TweenMax.to(NextBtnEl.current, 0.4, { opacity: 0, ease: "power2.inOut"})

    } catch (e) {
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }

  return (
    <Root img={BgImg}>
      { !isNextDisabled && 
      <Next  ref={NextBtnEl}>
          <button disabled={isDisabled} type="button" disabled={isDisabled} onTouchStart={hundleChangeScene('Guide2')}>
            <Push>次へ<br /><span>Next</span></Push>
          </button>
      </Next>
      }

      <TopButton onTouchStart={hundleChangeScene}  scene={"Top"} >TOPに戻る</TopButton>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  height: 100vh; /* 全画面表示 */
  width: 100vw; /* 全画面表示 */
  background: url(${props => props.img}) no-repeat center;
  background-size: cover;
  z-index: 0;
  &::before {
    content: "";
    background: inherit;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(5px);
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
  }
`

const Push =  styled.div`
  width: 500px;
  padding: 5rem 4rem;
  color: #fff;
  border-radius: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#ff3cac), color-stop(#562b7c), to(#2b86c5));
  background-image: -webkit-linear-gradient(left, #ff3cac 0%, #562b7c 50%, #2b86c5 100%);
  background-image: linear-gradient(90deg, #ff3cac 0%, #562b7c 50%, #2b86c5 100%);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  transform: skew(-15deg);
  -webkit-transform: skew(-15deg);
  font-size: 80px;
`
const Next = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0,0);
  -webkit-transform: translateY(-50%) translateX(-50%) scale(0,0);
`
const TopButton = styled(BackButton)`
  position: absolute;
  top: 10px;
  left: 15px;
`